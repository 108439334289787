<template>
  <div class="helpguide">
    <div class="helpguide-container">
     <div class="helpguide-title">
        <span>申请救助信息</span>
     </div>
     <div class="helpguidecontent">
         <ul>
             <li v-for="item in list" :key="item.id">
                 <dt>
                     <p @click="downFile(item.file, item.title)">《{{item.title}}》</p>
                     <!-- <span>（0-14岁贫困白血病儿童）</span> -->
                     <span>({{item.metaDescription}})</span>
                     <a :href="item.file" :download="item.title"></a>
                     <!-- <i @click="downFile(item.file, item.title)" ></i> -->
                 </dt>
<!--                 <dd>{{item.publishDate | formateData}}</dd>-->
             </li>
         </ul>
         <div class="pagination">
        <el-pagination
        class="bs-pagination"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
        </div>
     </div>
     <!-- <div class="contact-title">
         <span>联系我们</span>
     </div>
     <div class="contactus">
         <ul>
             <li>地址(临时办公区)：成都市武侯区人民南路四段55号附1号中国红十字会成都备</li>
             <li>电话：028-84350528</li>
             <li>邮编：610041</li>
         </ul>
     </div> -->
    </div>
  </div>
</template>

<script>
import apiUrls from "@api"
export default {
  name: 'helpguide',
  data () {
      return {
      list: [],
      page: 1,
      size: 10,
      total: 0
      }
  },
  created () {
    this.gethelpdate()
    this.handleCurrentChange()
  },
   methods : {
    downFile (urls,filename) {
      // 文件下载
      window.location.href=urls
      // download(urls)
      var a = document.createElement('a')
      a.href = urls
      a.download = filename+'.pdf'
    },
    gethelpdate () {
      apiUrls.gethelpdate({node: 'jzzn'}).then(res=>{
      this.list = res.results.data
      })
    },
    handleCurrentChange(val) {
      apiUrls.selfDynamicList({node: 'jzzn', page: val===1?0:val}).then(res=>{
      this.total = res.results.pager.totalElements
      this.list= res.results.data
      })
    }
    }
}
</script>

<style lang="less" scoped>
.helpguide {
    background-color: #f5f5f5;
  .helpguide-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;
    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
     .helpguide-title,.contact-title {
          margin-top: 53px;
          font-size: 22px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          span {
              &:nth-child(1) {
              display: flex;
              &::before {
              content: "";
              display: block;
              height: 20px;
              width: 20px;
              background: url('../../../assets/img/title.png') no-repeat center center;
            }
          }
            &:nth-child(2) {
                color: #ABABAB;
                font-size: 17px;
            }
          }
        }
        .helpguide-title {
          margin-top: 10px;
        }
        .helpguidecontent {
            min-height: 571px;
            background-color: #ffffff;
            padding: 39px 63px 39px 40px;
            box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
            ul {
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 40px;
                    dt {
                        display: flex;
                        p {
                          margin-right: 10px;
                          color: #1F6CA2;
						  cursor: pointer;
                        }
                        span {
                            margin-right: 10px;
							&:hover {
								color: #d1292e;
							}
                        }
                        a {
                        width: 18px;
                        height: 16px;
                        cursor: pointer;
                        text-decoration: none;
                        background: url('../../../assets/img/download.png') no-repeat center center;
                        &::after {
                            content: "305.kb";
                            position: relative;
                            left: 22px;
                            top: 2px;
                            font-size: 14px;
                            color: #999999;
                        }
                        }
                    //     &::after {
                    //     content: "";
                    //     display: block;
                    //     width: 18px;
                    //     height: 16px;
                    //     background: url('../../../assets/img/download.png') no-repeat center center;
                    // }
                        &::before {
                        content: "";
                        display: block;
                        width: 18px;
                        height: 16px;
                        background: url('../../../assets/img/list-little.png') no-repeat center center;
                        }
                    }
                    dd {
                        color: #ABABAB;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .pagination {
            margin-top: 40px;
            .bs-pagination {
             width: 656px;
            }
        }
        }
        .contactus {
            min-height: 205px;
            background-color: #ffffff;
            overflow: hidden;
            box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
            ul {
                margin: 40px  495px 47px 67px;
                li {
                    display: flex;
                    margin-bottom: 33px;
                    font-size: 17px;
                    align-items: center;
                    &:nth-child(1) {
                      &::before {
                        content: "";
                        display: block;
                        width: 30px;
                        height: 20px;
                        background: url('../../../assets/img/address.png') no-repeat 0 center;
                      }
                    }
                    &:nth-child(2) {
                      &::before {
                        content: "";
                        display: block;
                        width: 30px;
                        height: 20px;
                        background: url('../../../assets/img/phone.png') no-repeat 0 center;
                      }
                    }
                    &:nth-child(3) {
                    margin-bottom: 0;
                      &::before {
                        content: "";
                        display: block;
                        width: 30px;
                        height: 20px;
                        background: url('../../../assets/img/email.png') no-repeat 0 center;
                      }
                    }

                }
            }
        }
    }
}
</style>
